import React, {useContext, useEffect} from 'react';
import {Flex, Layout} from 'antd';
import ImageView from "../../common/ImageView";
import {AuthContext} from "../../../contexts/AuthContextProvider";
import {SettingContext} from "../../../contexts/SettingContextProvider";
import {retrieveImageUrl} from "../../../helpers/Utils";
import {useNavigate} from "react-router-dom";

import "./nav_header.scss";
import {MY_PROFILE_PATH} from "../../../routes/Slug";

const {Header} = Layout;

const NavHeader = () => {

    const navigate = useNavigate();

    const {isLogin, getProfile, profile} = useContext(AuthContext);
    const {getSetting} = useContext(SettingContext);

    useEffect(() => {
        if (isLogin) {
            getProfile();
            getSetting();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Header className="nav-header">

            <p className="brand-title">
                Enterprise Portal
            </p>

            <Flex align="center" gap={18}>
                <div className="image-wrapper">
                    <ImageView
                        url={retrieveImageUrl(profile?.profilePicture)}
                        style={{width: "20px", height: "20px"}}
                        onClick={() => navigate(MY_PROFILE_PATH)}
                    />
                </div>

                <div className="user-detail-wrapper">
                    <p className="user-type-txt">
                        {profile?.fullName}
                    </p>
                </div>

            </Flex>

        </Header>
    );
}

export default NavHeader;
