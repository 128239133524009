export const PRIMARY_COLOR = "#00A89E";
export const RED_COLOR = "#DA1E28";
export const GREEN_COLOR = "#24A148";
export const BORDER_COLOR = "#EAEAEA";
export const TXT_PRIMARY_COLOR = "#000000";
export const TXT_GRAY_COLOR = "#7A7A7A";
export const BODY_BG_COLOR = "#F4F4F4";
export const WHITE_COLOR = "#FFFFFF";
export const GRAY_2 = "#757575";

/*
* 80: Nav Header Height
* 72: Page Header Height
* 64: Table Header Height
* 64: Table Pagination Height
* 1: Miscellaneous
* */
export const TOTAL_CONTENT_HEIGHT = 80 + 72 + 64 + 64 + 1 + 80;
// export const TOTAL_CONTENT_HEIGHT = 80 + 72 + 64 + 64 + 1;