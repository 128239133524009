import {BORDER_COLOR, PRIMARY_COLOR, TXT_PRIMARY_COLOR, WHITE_COLOR} from "../helpers/ChatbotConstant";

export const lightToken = {
    colorPrimary: PRIMARY_COLOR,
    fontFamily: "'Barlow Condensed', 'Inter', 'Almarai', 'Noto Sans Bengali', sans-serif",
    colorInfo: PRIMARY_COLOR,
    descriptionBg: WHITE_COLOR, // Description background
    customBGC: WHITE_COLOR,
    colorTextBase: TXT_PRIMARY_COLOR,
    fontSize: 16,
}

export const lightComponentsToken = {
    Input: {
        colorPrimaryHover: BORDER_COLOR,
        controlHeight: 46,
        colorBorder: BORDER_COLOR,
        activeShadow: "0 0",
    },
    InputNumber: {
        colorPrimaryHover: BORDER_COLOR,
        controlHeight: 46,
        colorBorder: BORDER_COLOR,
        activeShadow: "0 0",
    },
    Select: {
        optionSelectedBg: PRIMARY_COLOR,
        optionSelectedColor: WHITE_COLOR,
    },
    DatePicker: {
        colorPrimaryHover: BORDER_COLOR
    },
    Table: {
        colorFillAlter: WHITE_COLOR,
        // headerBg: "#D3D3D3",
        headerBorderRadius: 0
    },
    Button: {
        controlHeight: "44px",
        borderRadius: "5px",
        primaryShadow: "0 0",
        fontWeight: 600,
    },
    Menu: {
        itemHeight: 44,
        fontSize: 16,
    },
    Modal: {
        borderRadiusLG: 5,
        titleFontSize: 20
    },
    Form: {
        itemMarginBottom: 14
    },
    Checkbox: {
        borderRadiusSM: 0,
        controlInteractiveSize: 20,
    }
}