import React, {createContext, useCallback, useState} from 'react';
import {addEdge, MarkerType, useEdgesState, useNodesState} from "reactflow";

export const WorkflowCanvasContext = createContext("WorkflowContext");

const WorkflowCanvasContextProvider = ({children}) => {

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const [nodeDrawerVisible, setNodeDrawerVisibility] = useState(false);
    const [nodeAttribute, setNodeAttribute] = useState(null);

    const [edgeLabelModalVisible, setEdgeLabelModalVisible] = useState(false);
    const [selectedEdgeConnection, setSelectedEdgeConnection] = useState(null);
    const [edgeLabel, setEdgeLabel] = useState("");
    const [isConnecting, setIsConnecting] = useState(false);
    const [openConnectionFailedModal, setOpenConnectionFailedModal] = useState({
        open: false,
        positionX: 0,
        positionY: 0,
        nodeId: null,
        handleId: null,
    });


    const [metadata, setMetadata] = useState(null);

    const [workflowData, setWorkflowData] = useState(null);

    const onDragStart = (event, nodeData) => {
        event.dataTransfer.setData("application/reactflow", nodeData.nodeType);
        event.dataTransfer.setData('nodeData', JSON.stringify(nodeData));
    };

    const onDragOver = (event) => {
        event.preventDefault(); // Allow dropping by preventing default behavior
    };

    const onConnect = useCallback(
        (connection) => {

            const sourceNode = nodes.find((node) => node.id === connection.source);
            setIsConnecting(false)
            console.log("first")
            setOpenConnectionFailedModal({
                open: false,
                positionX: 0,
                positionY: 0,
                nodeId: null,
                handleId: null,
            })

            const edge = {
                ...connection,
                type: 'custom',
                markerEnd: {
                    type: MarkerType.ArrowClosed,
                    width: 20,
                    height: 20,
                    color: '#ACA7FF',
                },
                style: {
                    strokeWidth: 2,
                    stroke: '#ACA7FF',
                },
            };

            /*
            * Start of maximum one output connection
            * */

            let filteredEdges = edges;

            if (!sourceNode?.data?.children[sourceNode?.data?.children?.length - 1]?.stateConfig?.choices) {
                // if (!sourceNode?.data?.children[sourceNode?.data?.children?.length - 1]?.children) {
                filteredEdges = edges.filter(edg => edg.source !== connection.source);
            }

            /*
            * End of maximum one output connection
            * */


            setEdges(() => addEdge(edge, filteredEdges));

        }, [ nodes, setEdges]
    );

    const openNodeDrawer = (item, parentId) => {

        setNodeAttribute({...item, parentId});
        setNodeDrawerVisibility(true);

    };

    const closeNodeDrawer = () => {

        setNodeDrawerVisibility(false);

    }

    const handleNodeConfigChange = e => {

        const {name, value} = e.target;

        const _nodeAttribute = {
            ...nodeAttribute,
            stateConfig: {
                ...nodeAttribute.stateConfig,
                [name]: value,
            }
        }

        setNodeAttribute(_nodeAttribute);
    }

    const addNodeAttributeTextVariants = () => {

        let _nodeAttributeTextVariants = [];

        if (nodeAttribute?.stateConfig?.displayTextOptions?.length > 0) {
            _nodeAttributeTextVariants = nodeAttribute.stateConfig.displayTextOptions;
        }

        const _nodeAttribute = {
            ...nodeAttribute,
            stateConfig: {
                ...nodeAttribute.stateConfig,
                displayTextOptions: [
                    ..._nodeAttributeTextVariants,
                    {id: new Date().getMilliseconds()}
                ]
            },
        }

        setNodeAttribute(_nodeAttribute);

    }

    const deleteNodeAttributeTextVariants = id => {

        const _nodeAttributeTextVariants = nodeAttribute?.stateConfig?.displayTextOptions?.filter(nodeAttributeTextVariant => nodeAttributeTextVariant.id !== id);

        const _nodeAttribute = {
            ...nodeAttribute,
            stateConfig: {
                ...nodeAttribute.stateConfig,
                displayTextOptions: _nodeAttributeTextVariants
            }
        }

        setNodeAttribute(_nodeAttribute);

    }

    const nodeAttributeTextVariantOnchange = (e, id) => {

        const {name, value} = e.target;

        const _nodeAttributeTextVariants = nodeAttribute?.stateConfig?.displayTextOptions?.map(nodeAttributeTextVariant => {
            if (nodeAttributeTextVariant.id === id) {
                return {
                    ...nodeAttributeTextVariant,
                    [name]: value
                }
            }
            return nodeAttributeTextVariant;
        });

        const _nodeAttribute = {
            ...nodeAttribute,
            stateConfig: {
                ...nodeAttribute.stateConfig,
                displayTextOptions: _nodeAttributeTextVariants,
            }
        }

        setNodeAttribute(_nodeAttribute)

    }

    const handleDevWebhookConfig = e => {

        const {name, value} = e.target;

        const _nodeAttribute = {
            ...nodeAttribute,
            stateConfig: {
                ...nodeAttribute.stateConfig,
                webhookConfig: {
                    ...nodeAttribute.stateConfig.webhookConfig,
                    [name]: value,
                }
            }
        }

        setNodeAttribute(_nodeAttribute);
    }

    const closeEdgeLabelModal = () => {
        setEdgeLabelModalVisible(false);
    }

    const handleEdgeLabelAdd = () => {
        if (selectedEdgeConnection) {
            // When the user clicks 'OK' in the modal, add the edge with the provided label
            const edge = {
                ...selectedEdgeConnection,
                type: 'custom',
                label: edgeLabel, // Use the input value as the label
                markerEnd: {
                    type: MarkerType.Arrow,
                    width: 20,
                    height: 20,
                    color: '#00A89E',
                },
                style: {
                    strokeWidth: 2,
                    stroke: '#00A89E',
                },
            };

            setEdges((eds) => addEdge(edge, eds));
            setEdgeLabel('');
            setSelectedEdgeConnection(null);

            closeEdgeLabelModal();

        }
    }

    const handleEdgeLabelChange = value => {
        setEdgeLabel(value);
    }

    return (
        <WorkflowCanvasContext.Provider
            value={{
                nodes,
                setNodes,
                edges,
                setEdges,
                onNodesChange,
                onEdgesChange,
                nodeDrawerVisible,
                edgeLabelModalVisible,
                onConnect,
                nodeAttribute,
                setNodeAttribute,
                setMetadata,
                metadata,
                workflowData,
                setWorkflowData,
                setIsConnecting,
                isConnecting,
                openConnectionFailedModal,
                setOpenConnectionFailedModal,
                handleDevWebhookConfig,
                openNodeDrawer,
                onDragStart,
                onDragOver,
                closeNodeDrawer,
                handleNodeConfigChange,
                handleEdgeLabelAdd,
                handleEdgeLabelChange,
                closeEdgeLabelModal,
                addNodeAttributeTextVariants,
                deleteNodeAttributeTextVariants,
                nodeAttributeTextVariantOnchange,
            }}
        >
            {children}
        </WorkflowCanvasContext.Provider>
    );
}

export default WorkflowCanvasContextProvider;
