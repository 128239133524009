import {CONDITION_NODE_TYPE, DEV_WEBHOOK_NODE_TYPE, QUESTION_NODE_TYPE, REPLY_TEXT_NODE_TYPE} from "./Constant";

export const workflowTemplates = {
    data : {
        content: [
            {
                "metadata": {
                    "topicName": "AppointmentBook",
                    "description": "Appointment Book",
                    "chatbot": "Dev bot 9",
                    "language": "ENG",
                    "stage": "UAT",
                    "chatbotId": 22,
                    "languageId": 1,
                    "id": 355
                },
                "nodes": [
                    {
                        "id": "1388",
                        "position": {
                            "x": 307,
                            "y": 175
                        },
                        "data": {
                            "label": "New Block",
                            "children": [
                                {
                                    "id": 1008,
                                    "label": "Question",
                                    "nodeType": "QUESTION",
                                    "stateConfig": {
                                        "name": "name",
                                        "variable": "name",
                                        "displayTextOptions": [
                                            {
                                                "id": 669,
                                                "displayText": "May I have your name, please?"
                                            }
                                        ]
                                    }
                                },
                                {
                                    "id": 559,
                                    "label": "Question",
                                    "nodeType": "QUESTION",
                                    "stateConfig": {
                                        "name": "email",
                                        "variable": "email",
                                        "displayTextOptions": [
                                            {
                                                "id": 318,
                                                "displayText": "Thanks,{name}. Can you share your email ?"
                                            }
                                        ]
                                    }
                                }
                            ]
                        },
                        "type": "MainWorkflowNode",
                        "dragHandle": ".custom-drag-handle",
                        "style": {
                            "borderRadius": "12px",
                            "width": "300px"
                        },
                        "width": 300,
                        "height": 190,
                        "selected": false,
                        "dragging": false,
                        "positionAbsolute": {
                            "x": 307,
                            "y": 175
                        }
                    },
                    {
                        "id": "1022",
                        "position": {
                            "x": 663,
                            "y": 205
                        },
                        "data": {
                            "label": "New Block",
                            "children": [
                                {
                                    "id": 1633,
                                    "label": "Question",
                                    "nodeType": "QUESTION",
                                    "stateConfig": {
                                        "name": "appointmentType",
                                        "variable": "appointmentType",
                                        "displayTextOptions": [
                                            {
                                                "id": 868,
                                                "displayText": "Could you please specify the service you are looking for: Echocardiogram, ECG, Exercise Stress Test, Ambulatory Blood Pressure Monitoring, Holter Monitoring, or Consultations?"
                                            }
                                        ]
                                    }
                                },
                                {
                                    "id": 1613,
                                    "label": "Question",
                                    "nodeType": "QUESTION",
                                    "stateConfig": {
                                        "name": "ask-dateTime",
                                        "variable": "dateTime",
                                        "displayTextOptions": [
                                            {
                                                "id": 599,
                                                "displayText": "When are you planning to schedule the appointment ?"
                                            }
                                        ]
                                    }
                                },
                                {
                                    "id": 588,
                                    "label": "Question",
                                    "nodeType": "QUESTION",
                                    "stateConfig": {
                                        "name": "confirm",
                                        "variable": "confirm",
                                        "displayTextOptions": [
                                            {
                                                "id": 548,
                                                "displayText": "Thank you for providing the information. Summary of your appointment: Email: {email}, Name: {name}, Service: {appointmentType}, Date and Time: {dateTime}. Please confirm the details. Answer yes/no."
                                            }
                                        ]
                                    }
                                },
                                {
                                    "id": 919,
                                    "label": "Condition",
                                    "nodeType": "CONDITION",
                                    "stateConfig": {
                                        "choices": [
                                            {
                                                "id": 514,
                                                "operator": "OR",
                                                "expressionList": [
                                                    {
                                                        "id": 414,
                                                        "operator": "is",
                                                        "variable": "confirm",
                                                        "value": "yes"
                                                    }
                                                ],
                                                "next": "1028"
                                            },
                                            {
                                                "id": 514.3604773477849,
                                                "operator": "DEFAULT",
                                                "name": "",
                                                "active": true
                                            }
                                        ],
                                        "next": "1096"
                                    }
                                }
                            ]
                        },
                        "type": "MainWorkflowNode",
                        "dragHandle": ".custom-drag-handle",
                        "style": {
                            "borderRadius": "12px",
                            "width": "300px"
                        },
                        "width": 300,
                        "height": 338
                    },
                    {
                        "id": "1787",
                        "position": {
                            "x": 1133.9475331076146,
                            "y": 117.87834371081073
                        },
                        "data": {
                            "label": "New Block",
                            "children": [
                                {
                                    "id": 1028,
                                    "label": "Dev Webhook",
                                    "nodeType": "DEV_WEBHOOK",
                                    "stateConfig": {
                                        "name": "send-email",
                                        "webhookConfig": {
                                            "url": "https://5e7roplfzvvmd6ebnt2mkawtxa0lpfas.lambda-url.us-east-1.on.aws",
                                            "method": "POST",
                                            "requestBody": "{\n  \"clinicEmail\": \"CLINICEMAIL\",\n  \"email\": {email},\n  \"name\": {name},\n  \"appointmentType\": {appointmentType},\n  \"dateTime\": {dateTime},\n}"
                                        }
                                    }
                                },
                                {
                                    "id": 1023,
                                    "label": "Reply Text",
                                    "nodeType": "REPLY_TEXT",
                                    "stateConfig": {
                                        "name": "success",
                                        "displayTextOptions": [
                                            {
                                                "id": 558,
                                                "displayText": "success"
                                            }
                                        ]
                                    }
                                }
                            ]
                        },
                        "type": "MainWorkflowNode",
                        "dragHandle": ".custom-drag-handle",
                        "style": {
                            "borderRadius": "12px",
                            "width": "300px"
                        },
                        "width": 300,
                        "height": 188,
                        "selected": false,
                        "positionAbsolute": {
                            "x": 1133.9475331076146,
                            "y": 117.87834371081073
                        },
                        "dragging": false
                    },
                    {
                        "id": "731",
                        "position": {
                            "x": 232,
                            "y": 668
                        },
                        "data": {
                            "label": "New Block",
                            "children": [
                                {
                                    "id": 1096,
                                    "label": "Reply Text",
                                    "nodeType": "REPLY_TEXT",
                                    "stateConfig": {
                                        "name": "else",
                                        "displayTextOptions": [
                                            {
                                                "id": 577,
                                                "displayText": "sure what are the changes you need to make"
                                            }
                                        ]
                                    }
                                }
                            ]
                        },
                        "type": "MainWorkflowNode",
                        "dragHandle": ".custom-drag-handle",
                        "style": {
                            "borderRadius": "12px",
                            "width": "300px"
                        },
                        "width": 300,
                        "height": 138,
                        "selected": false,
                        "positionAbsolute": {
                            "x": 232,
                            "y": 668
                        },
                        "dragging": false
                    },
                    {
                        "id": "983",
                        "position": {
                            "x": -77,
                            "y": -44
                        },
                        "data": {
                            "label": "New Block",
                            "children": [
                                {
                                    "id": 1297,
                                    "label": "Reply Text",
                                    "nodeType": "REPLY_TEXT",
                                    "stateConfig": {
                                        "name": "greet",
                                        "displayTextOptions": [
                                            {
                                                "id": 953,
                                                "displayText": "hello"
                                            }
                                        ]
                                    }
                                }
                            ]
                        },
                        "type": "MainWorkflowNode",
                        "dragHandle": ".custom-drag-handle",
                        "style": {
                            "borderRadius": "12px",
                            "width": "300px"
                        },
                        "width": 300,
                        "height": 138,
                        "selected": false,
                        "positionAbsolute": {
                            "x": -77,
                            "y": -44
                        },
                        "dragging": false
                    }
                ],
                "edges": [
                    {
                        "source": "1388",
                        "sourceHandle": "source-1388-559",
                        "target": "1022",
                        "targetHandle": "target-1022",
                        "type": "custom",
                        "markerEnd": {
                            "type": "arrow",
                            "width": 20,
                            "height": 20,
                            "color": "#969b9f"
                        },
                        "style": {
                            "strokeWidth": 2,
                            "stroke": "#969b9f"
                        },
                        "id": "reactflow__edge-1388source-1388-559-1022target-1022"
                    },
                    {
                        "source": "1022",
                        "sourceHandle": "source-condition-919-514",
                        "target": "1787",
                        "targetHandle": "target-1787",
                        "type": "custom",
                        "markerEnd": {
                            "type": "arrow",
                            "width": 20,
                            "height": 20,
                            "color": "#969b9f"
                        },
                        "style": {
                            "strokeWidth": 2,
                            "stroke": "#969b9f"
                        },
                        "id": "reactflow__edge-1022source-condition-919-514-1787target-1787"
                    },
                    {
                        "source": "1022",
                        "sourceHandle": "source-else-condition-919",
                        "target": "731",
                        "targetHandle": "target-731",
                        "type": "custom",
                        "markerEnd": {
                            "type": "arrow",
                            "width": 20,
                            "height": 20,
                            "color": "#969b9f"
                        },
                        "style": {
                            "strokeWidth": 2,
                            "stroke": "#969b9f"
                        },
                        "id": "reactflow__edge-1022source-else-condition-919-731target-731"
                    },
                    {
                        "source": "983",
                        "sourceHandle": "source-983-1297",
                        "target": "1388",
                        "targetHandle": "target-1388",
                        "type": "custom",
                        "markerEnd": {
                            "type": "arrow",
                            "width": 20,
                            "height": 20,
                            "color": "#969b9f"
                        },
                        "style": {
                            "strokeWidth": 2,
                            "stroke": "#969b9f"
                        },
                        "id": "reactflow__edge-983source-983-1297-1388target-1388"
                    },
                    {
                        "source": "731",
                        "sourceHandle": "source-731-1096",
                        "target": "1388",
                        "targetHandle": "target-1388",
                        "type": "custom",
                        "markerEnd": {
                            "type": "arrow",
                            "width": 20,
                            "height": 20,
                            "color": "#969b9f"
                        },
                        "style": {
                            "strokeWidth": 2,
                            "stroke": "#969b9f"
                        },
                        "id": "reactflow__edge-731source-731-1096-1388target-1388"
                    }
                ]
            }
        ],
        totalElements: 1,
    }
}

export const formInfoMessage = {
    "QUESTION": {
        header: "Prompt: ",
        message: "The prompt is the message sent to the user, and the user's response is stored in a slot for further processing.",
    },
    "REPLY_TEXT": {
        header: "Text Message: ",
        message: "The response sent to a user during a conversation, providing information or interaction based on the ongoing dialogue."
    },
    "DEV_WEBHOOK": {
        header: "API Step: ",
        message: "Make custom API calls to retrieve data from external databases or send data to other sources, enabling seamless integration and data exchange with external systems."
    },
    "CONDITION": {
        header: "Conditional State Execution: ",
        message: "Implement varying actions based on \"if, then\" logic. This allows you to set conditions that trigger specific actions only when certain criteria's are met"
    }
}